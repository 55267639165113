import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Container, Card, Flex, Box, Link, Message } from 'theme-ui';
import { PageStructure } from '../../components/help-center/PageStructure';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageStructure layoutProps={props} title="Creating a Company" prev={{
  name: 'Creating an Account',
  path: '/creating-an-account'
}} next={{
  name: 'Account Initialization Process',
  path: '/account-initialization-process'
}}>
        {props.children}
    </PageStructure>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Now that you have an account it is time to create your first company.`}</p>
    <Message sx={{
      mb: 4
    }} mdxType="Message">
You can have multiple companies in your account.
    </Message>
    <p>{`This process begins on the `}<a parentName="p" {...{
        "href": "https://app.simplereviewmanagement.com/create-company"
      }}>{`create company`}</a>{` page.`}</p>
    <h2 {...{
      "id": "create-company-page"
    }}>{`Create Company Page`}</h2>
    <Message sx={{
      mb: 4
    }} mdxType="Message">
If you created your user account with Google you may get to skip this step.
    </Message>
    <p>{`The two fields on this page are required. They are your company name and customer facing email address. Both will be included in the review requests sent to customers.`}</p>
    <p>{`When you are done on this page, save and continue to the `}<a parentName="p" {...{
        "href": "https://app.simplereviewmanagement.com/integrate-company"
      }}>{`integration page`}</a>{`.`}</p>
    <h2 {...{
      "id": "integration-page"
    }}>{`Integration Page`}</h2>
    <Message sx={{
      mb: 4
    }} mdxType="Message">
If you created your user account with Google you may find Google is already connected and you can skip this step.
    </Message>
    <p>{`Connecting your Simple RM account to your other business accounts lets Simple RM view data in those accounts. This is necessary for Simple RM to function correctly.`}</p>
    <p>{`The one required connection is with your Google My Business account. It is needed to be able to determine which reviews were the result of a request sent from Simple RM.`}</p>
    <p>{`Click the connect button in the Google My Business card to initiate the process. You can read more about the connection process in `}<a parentName="p" {...{
        "href": "/help-center/connect-google-my-business"
      }}>{`this article`}</a>{`.`}</p>
    <p>{`Once you connect your Google My Business account the button to proceed to the next step will become clickable and you can move forward to the `}<a parentName="p" {...{
        "href": "https://app.simplereviewmanagement.com/pricing"
      }}>{`payment page`}</a>{`.`}</p>
    <h2 {...{
      "id": "payment-page"
    }}>{`Payment Page`}</h2>
    <p>{`The final step of the setup process is adding a payment method for your company.`}</p>
    <p>{`A payment method is required to send review requests. However, if you do not get any reviews you will not be charged.`}</p>
    <p>{`Click continue and you will be brought to the screen where you can enter your payment information.`}</p>
    <p>{`Enter the required information and move to the next step.`}</p>
    <h2 {...{
      "id": "whats-next"
    }}>{`What’s Next`}</h2>
    <p>{`All that is left is for Simple RM to do some work on its side before you can access your `}<a parentName="p" {...{
        "href": "/help-center/account-dashboard-intro"
      }}>{`account dashboard`}</a>{`.`}</p>
    <p>{`You will be presented with a screen that features a button to kick off an `}<a parentName="p" {...{
        "href": "/help-center/account-initialization-process"
      }}>{`account initialization process`}</a>{`.`}</p>
    <p>{`Click the button and wait for the process to complete.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      